.chartdiv{
    /* background-color: red; */
}
.salse-Chart{
    margin-left: -15px;
}
.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    box-shadow: none;
    display: none;
}