* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

@font-face {
  font-family: "inter-bold";
  src: url(../fonts/inter-bold.ttf);
}

@font-face {
  font-family: "inter-medium";
  src: url(../fonts/inter-medium.ttf);
}

@font-face {
  font-family: "inter-regular";
  src: url(../fonts/inter-regular.ttf);
}

@font-face {
  font-family: "inter-semibold";
  src: url(../fonts/inter-semibold.ttf);
}

.d-flex,
.res-menu-icon {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-auto {
  margin-left: auto;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.h-100 {
  height: 100%;
}

.opacity-60 {
  opacity: 0.6;
}

.header-main {
  background: #003d90;
  padding: 4px 15px;
}

.position-relative {
  position: relative;
}

.header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-ul {
  display: flex;
  color: #d4d9e1;
}

.header-ul li {
  font-family: "inter-regular", sans-serif;
  font-size: 14px;
  width: auto;
  padding: 0 10px;
}

.logo-img {
  width: 178px;
  object-fit: cover;
  height: 38px;
}

.header-ul li a {
  text-decoration: none;
  color: #d4d9e1;
}

.p-15 {
  padding: 15px;
}

.title-header {
  display: flex;
  align-items: center;
}

.title-header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.title-header .cus-title-header {
  flex-direction: column !important;
}

.search {
  border: 1px solid #d4d9e1;
  width: 200px !important;
  height: 35px;
  line-height: 31px;
  border-radius: 4px !important;
  margin-right: 10px !important;
}

.search input::placeholder {
  color: #919cac;
  opacity: 1;
}

.search input {
  font-size: 12px;
  line-height: 15px;
  padding: 5px 14px !important;
  font-family: "inter-regular", sans-serif;
}

.dashboard-text p,
.title-main .page-title {
  color: #003d90;
  font-size: 19px;
  line-height: 20px;
  font-family: "inter-bold", sans-serif;
}

.order-document-heading {
  margin-top: 50px !important;
}

.common-button button,
.common-button button:hover {
  font-size: 12px;
  font-family: "inter-semibold", sans-serif;
  padding: 7px 13px 6px 13px;
  line-height: 19px;
  box-shadow: none;
  letter-spacing: 0px;
  border-radius: 4px;
  text-transform: capitalize;
}

.grey-button button,
.grey-button button:hover {
  background: #fff;
  border: 1px solid #eaac35;
  color: #eaac35;
  border-radius: 4px;
}

.blue-button button,
.blue-button button:hover {
  background: #eaac35;
  color: #ffffff;
  /* margin-left: 10px; */
  border-radius: 4px;
  border: 0px;
  padding: 7px 8px;
}

.assign-btn button,
.assign-btn button:hover {
  padding: 7px 6px;
}

.assign-btn-align .assign-button-margin {
  margin-left: 2px !important;
}

.table-design-main tr th,
.table-design-main tr td {
  font-size: 13px;
  color: #122b45;
}

.table-design-main tr th {
  font-family: "inter-semibold", sans-serif;
  padding: 4px 9px !important;
}

.table-design-main tr td {
  font-family: "inter-regular", sans-serif;
  padding: 5px 9px !important;
  border-bottom: 0 !important;
}

.party-management-table tr td:nth-child(2) {
  text-transform: lowercase;
}

.table-design-main {
  box-shadow: none !important;
  border: 0 !important;
}

.invoice-table tr td {
  background: #fff;
  display: table-cell;
  text-align: left;
}

.input-design-div .password {
  margin-top: 25px !important;
}

.input-design-div .MuiFilledInput-underline:before,
.input-design-div .MuiFilledInput-underline:after,
.input-design-div .MuiFilledInput-underline:hover:before {
  border-bottom: 0 !important;
}

.input-design-div .MuiFilledInput-underline,
.input-design-div .MuiFilledInput-underline:hover,
.input-design-div .MuiFilledInput-underline.Mui-focused {
  background: #fff;
  border: 1px solid #d6e5ff;
  border-radius: 4px;
  height: 28px;
}

.input-design-div .input-placeholder input::placeholder {
  color: #122b45;
  font-family: "inter-regular", sans-serif;
  line-height: 20px;
}

.maintenance-view-input-box {
  margin-bottom: 10px;
}

.input-design-div .maintenance-view-disabled-input .Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.808);
}

.input-design-div .textarea-input-disabled:disabled {
  background-color: #ffffff;
}

.admin-input-design {
  width: 100%;
}

.input-design-div.with-border input {
  height: 35px;
  line-height: 28px;
  box-sizing: border-box;
  padding: 0 15px;
}

.input-design-div.with-border input,
.set-textarea-box-top .MuiInputBase-formControl {
  border: 1px solid #d6e5ff;
  border-radius: 4px;
  background-color: #fff;
  font-family: "inter-regular", sans-serif;
  font-size: 14px;
}

.set-textarea-box-top fieldset {
  border: none !important;
}

.with-border .MuiFilledInput-underline {
  height: 35px !important;
  font-size: 14px !important;
  letter-spacing: 0;
}

.input-label {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;
  font-family: "inter-semibold", sans-serif;
  padding-bottom: 8px !important;
  display: flex;
}

.title-main .input-label-bold {
  font-family: "inter-bold", sans-serif;
}

.line {
  height: 1px;
  background: #d6e5ff;
}

.save-btn button,
.save-btn button:hover,
.save-btn button:focus {
  min-width: 110px;
}

.user-submit-btn button {
  margin-top: 35px;
}

.table-container {
  box-shadow: none !important;
}

.set-date.set-date-picker {
  display: flex;
  align-items: center;
  gap: 10px;
}

.set-date.set-date.set-date-picker .reset-icon {
  padding: 0;
}

.set-date.set-date.set-date-picker .reset-icon svg {
  width: 22px;
  height: 22px;
}

.set-date .MuiStack-root {
  overflow: hidden;
  padding-top: 0px;
}

.set-date-picker .MuiInputBase-colorPrimary {
  background: #fff;
  border: 1px solid #d6e5ff !important;
  border-radius: 4px;
  width: 100%;
  height: 35px;
}

.set-date-picker fieldset {
  border-color: transparent !important;
}

.set-date-picker input {
  padding: 0px 14px !important;
  color: #120d26;
  font-size: 14px !important;
  line-height: 19px;
  font-family: "inter-regular", sans-serif !important;
  text-align: left;
  height: 28px;
}

.set-date-picker .MuiStack-root {
  padding-top: 0px !important;
}

.set-calendar-box .set-calendar-position,
.set-date-picker .MuiStack-root,
.input-design-div-people {
  width: 100%;
}

.accestimeicon {
  fill: #757575 !important;
}

.accestimeicon-img {
  position: absolute;
  top: 5px;
  right: 8px;
  display: flex;
}

.accestimeicon-img svg {
  height: 19px;
}

.set-date-picker svg {
  height: 19px;
}

.pagination-design nav {
  display: flex;
}

.pagination-design nav ul li .MuiPaginationItem-previousNext {
  color: #003d90;
}

.pagination-design nav ul li button,
.pagination-design nav ul li button:hover {
  min-width: auto;
  height: auto;
  background: transparent;
  margin: 0;
  font-family: "inter-medium", sans-serif;
  color: #99a4b5;
}

.pagination-design nav ul li button.Mui-selected,
.pagination-design nav ul li button.Mui-selected:hover {
  background: transparent;
  color: #003d90;
}

.pagination-design {
  margin-top: 15px;
}

.filter-btn button,
.filter-btn button:hover {
  max-width: 34px;
  height: 34px;
  min-width: 34px;
  background: #1976d2;
  border: 1px solid #1976d2;
}

/* 10-5 css */

.main-login {
  background-image: url("../images/png/backlogin.png");
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightbox {
  background: #042443c4;
  height: 100vh;
  margin-left: auto;
  max-width: 50%;
  border-radius: 0px 0px 0px 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-login-main {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-set {
  padding: 30px 0px 0px 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.logo-set img {
  height: 81px;
  width: 383px;
  object-fit: contain;
}

.logo-set2 img {
  height: 63px;
  width: 300px;
  object-fit: contain;
}

.box-text p {
  color: white;
  font-size: 32px;
  line-height: 39px;
  font-family: "inter-bold", sans-serif;
}

.box-login-text p {
  font-family: "inter-regular", sans-serif;
  font-size: 16px;
  color: white;
  line-height: 20px;
  margin: 15px 0px 40px 0px;
}

.main-box {
  max-width: 300px !important;
  width: 300px;
  flex: 0 0 300px;
}

.login-btn-main {
  margin: 30px 0px 20px;
}

.login-btn button {
  width: 100%;
  font-family: "inter-semibold", sans-serif;
}

.orange-btn button,
.orange-btn button:hover,
.orange-btn .buttons,
.orange-btn .buttons:hover {
  background: #eaac35;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  min-height: 36px;
  font-family: "inter-regular", sans-serif;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  text-decoration: none !important;
  min-width: 300px;
  box-shadow: 0px 3px 4px #ccc4b3;
}

.forgot p {
  margin: 0px !important;
  text-align: right !important;
}

.input-design-div .MuiInputBase-input {
  padding: 3px 10px !important;
  color: #122b45;
  background-color: #fff;
  border-radius: 4px;
  font-size: 14px;
  padding-right: 30px !important;
}

.dashboard-text p {
  color: #003d90;
  font-size: 16px;
  line-height: 20px;
  font-family: "inter-bold", sans-serif;
  display: flex;
}

.main-box-dash {
  background: #f0f3f8;
  border-radius: 8px;
  max-width: 18.81%;
  min-height: 73px;
  padding: 14px 20px;
  width: calc(100% / 5);
  margin: 10px 7.5px 15px;
}

.text-main p {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  font-family: "inter-medium", sans-serif;
  margin-bottom: 5px;
}

.number-text p {
  font-size: 16px;
  line-height: 20px;
  font-family: "inter-bold", sans-serif;
  color: #122b45;
}

.logo-dash img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.dash-text {
  margin-left: 20px !important;
}

.dash-text-box-set {
  display: flex;
  align-items: center;
}

.set-dash-main {
  display: flex;
  width: calc(100% + 15px);
  margin-left: -7.5px;
  flex-wrap: wrap;
  margin-right: -7.5px;
}

.forgot a {
  text-decoration: none;
}

.barge-list-main-box {
  border-radius: 8px;
  max-width: 100%;
  min-height: 104px;
  background: #f0f3f8;
}

.barge-set-dash-main {
  display: flex;
  margin-left: -6.5px;
  flex-wrap: wrap;
  margin-right: -6.5px;
}

.brge-icon .brge-icon-set {
  width: 66px;
  height: 59px;
}

.set-progress {
  margin: 3px 0px 1px;
}

.set-progress span {
  width: 66px !important;
  height: 4px !important;
}

.set-progress .MuiLinearProgress-bar {
  background-color: #0d70d9 !important;
}

.brge-number .text {
  font-size: 8px;
  line-height: 10px;
  font-family: "inter-medium", sans-serif;
  color: #122b45;
}

.brge-img-set {
  padding: 12px 15px 10px;
  width: 30%;
  border-right: 1px solid #dfe0e1;
}

.barge-set-text-box {
  padding: 8px 8px 10px 15px;
  width: 100%;
  text-align: left;
}

.main-barge-box {
  display: flex;
}

.set-scheduled .text {
  font-size: 8px;
  line-height: 10px;
  font-family: "inter-regular", sans-serif;
  color: white;
  padding: 1px 6px;
}

.set-scheduled {
  border-radius: 30px;
  width: 100%;
  max-width: max-content;
  margin-left: auto;
  height: 12px;
}

.set-scheduled-text .text-name {
  font-size: 10px;
  line-height: 12px;
  font-family: "inter-regular", sans-serif;
  color: #8d98ab;
}

.set-scheduled-text .text-span {
  font-size: 10px;
  line-height: 12px;
  font-family: "inter-medium", sans-serif;
  color: #122b45;
}

.set-scheduled-back {
  background: #51c6e5;
}

.set-available-back {
  background-color: #05e39c;
}

.main-bargebox {
  display: flex;
  width: 100%;
}

.todaytrip-list-main-box {
  background: #f0f3f8;
  border-radius: 8px;
  max-width: 100%;
  min-height: 104px;
  /* / padding: 12px 15px 10px; / */
  /* / width: calc(100% / 2); / */
  /* border: 1px solid #DFE0E1; */
  /* / margin: 15px 6.5px 0px; / */
}

.dashboard-row-main .dashboard-item,
.dashboard-item {
  padding: 0 !important;
  box-shadow: none !important;
  background: transparent;
  text-align: center !important;
}

.background-ed {
  background: #fff;
  border-radius: 10px;
}

.today-trip-text {
  padding-top: 12px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.today-set-progress.set-progress .MuiLinearProgress-bar {
  background-color: #e8b153 !important;
}

.profile-set {
  font-family: "inter-regular", sans-serif;
  font-size: 14px;
  width: auto;
  padding: 0 10px;
  color: #d4d9e1;
  text-transform: capitalize;
}

.flex-drop-main {
  display: flex;
  align-items: center;
}

.admin-header-profile-icon {
  height: 26px;
  width: 26px;
  object-fit: cover;
  border-radius: 50%;
}

.arrow-profile-icon {
  height: 5px;
  width: 5px;
  object-fit: cover;
}

.admin-header-profile-ul a li {
  color: #122b45;
  font-size: 14px;
  line-height: 17px;
  font-family: "inter-regular", sans-serif;
}

.admin-header-profile-ul a {
  text-decoration: none !important;
}

.logout-profile {
  color: #f5125f !important;
}

.admin-profile-box .MuiPaper-rounded {
  top: 50px !important;
  right: 32px !important;
  box-shadow: 0px 3px 6px #0000000d !important;
  border-radius: 10px !important;
  left: 0px !important;
}

/* / start admin header css / */
.admin-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* / end admin header css / */

/* 10-5 css end */
.filter-header {
  background: #edeff1;
  padding: 15px 20px;
}

.filter-main .MuiPaper-elevation {
  min-width: 350px;
}

.filter-header-inner .filter-title {
  color: #003d90;
  font-family: "inter-bold", sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.filter-footer {
  height: 50px;
  border: 1px solid #edeff1;
  padding: 10px 20px;
}

.filter-inner-main {
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 20px;
}

.filter-header-inner {
  justify-content: space-between;
}

.outline-button button,
.outline-button button:hover {
  background: transparent;
  border: 1px solid #eaac35;
  color: #eaac35;
  border-radius: 4px;
}

.profile-content {
  height: 100%;
  min-height: calc(100vh - 120px);
}

.card-main {
  background: #f0f6ff;
  padding: 30px;
  /* box-shadow: 0px 3px 30px #0000000d; */
  border-radius: 10px;
  border: 1px solid #d6e5ff;
}

.profile-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  margin: auto;
  margin-bottom: 15px;
  border: 1px solid #d6e5ff;
}

.profile-card-main {
  width: 710px;
}

.change-input-label {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;
  font-family: "inter-medium", sans-serif;
  padding-bottom: 6px !important;
  display: flex;
}

.set-textarea-box {
  height: 47px;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px 16px;
  resize: none;
}

.set-textarea-box:focus {
  outline: none;
}

.change-profile {
  display: flex;
  justify-content: center;
  position: relative;
}

input.change-profile-input {
  position: absolute;
  top: 1px;
  background: red;
  height: 28px;
  right: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
}

.change-password-set {
  margin-bottom: 20px;
}

.change-password-main {
  min-width: 370px;
  max-width: 370px;
}

.change-space {
  margin-bottom: 15px;
}

.supply-table tr th:nth-child(1),
.supply-table tr td:nth-child(1) {
  width: 6%;
}

.supply-table tr th:nth-child(2),
.supply-table tr td:nth-child(2) {
  width: 10%;
}

.tug-supply-table tr th:nth-child(2),
.tug-supply-table tr td:nth-child(2) {
  width: 2%;
}

.supply-table tr th:nth-child(3),
.supply-table tr td:nth-child(3) {
  width: 10%;
}

.supply-table tr th:nth-child(4),
.supply-table tr td:nth-child(4) {
  width: 5%;
}

.supply-table tr th:nth-child(5),
.supply-table tr td:nth-child(5) {
  width: 11%;
}

.supply-table tr th:nth-child(6),
.supply-table tr td:nth-child(6) {
  width: 6%;
}

.supply-table tr th:nth-child(7),
.supply-table tr td:nth-child(7) {
  width: 8%;
}

.supply-table tr th:nth-child(8),
.supply-table tr td:nth-child(8) {
  width: 5%;
}

.supply-table tr th:nth-child(9),
.supply-table tr td:nth-child(9) {
  width: 5%;
}

.supply-table tr th:nth-child(10),
.supply-table tr td:nth-child(10) {
  width: 4%;
}

.supply-table tr th:nth-child(11),
.supply-table tr td:nth-child(11) {
  width: 7%;
}

.supply-table tr th:nth-child(12),
.supply-table tr td:nth-child(12) {
  width: 10%;
}

.supply-table tr th:nth-child(13),
.supply-table tr td:nth-child(13) {
  width: 18%;
}

.supply-table tr th:nth-child(14),
.supply-table tr td:nth-child(14) {
  width: 10%;
}

.supply-table tr th:nth-child(1) label,
.supply-table tr td:nth-child(1) label {
  margin-right: 0;
}

.tanker-table tr th:nth-child(1),
.tanker-table tr td:nth-child(1) {
  width: 12%;
}

.tanker-table tr th:nth-child(2),
.tanker-table tr td:nth-child(2) {
  width: 20%;
}

.tanker-table tr th:nth-child(3),
.tanker-table tr td:nth-child(3) {
  width: 12%;
}

.tanker-table tr th:nth-child(4),
.tanker-table tr td:nth-child(4) {
  width: 14%;
}

.tanker-table tr th:nth-child(5),
.tanker-table tr td:nth-child(5) {
  width: 18%;
}

.tanker-table tr th:nth-child(6),
.tanker-table tr td:nth-child(6) {
  width: 17%;
}

.tanker-table tr th:nth-child(7),
.tanker-table tr td:nth-child(7) {
  width: 20%;
}

.change-password-btn button {
  margin-left: 0 !important;
  margin-top: 5px;
}

.res-menu {
  display: none;
}

.res-menu-icon svg {
  fill: #fff;
  width: 20px;
  height: 20px;
}

.res-menu-icon svg {
  display: none;
}

.res-close-btn {
  display: none !important;
}

.header-flex-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.set-filter-main {
  padding-top: 60px;
}

.background-hight {
  height: 100%;
}

.header-ul-main-open {
  left: -250px !important;
}

.menu-set-main .MuiMenu-paper {
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  width: 160px;
}

.menu-set-main ul {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.no-text-decoration {
  text-decoration: none;
}

.margin-left-5 {
  margin-left: 5px !important;
}

/* start add user modal css */

.span-text {
  color: #d1d5db;
  margin-left: 3px;
}

.modal .modal-title {
  color: #111827;
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Medium", sans-serif;
}

.modal-user-btn-flex {
  display: flex;
  justify-content: flex-end;
  padding-top: 3px;
}

.modal .add-user-modal-inner-main {
  padding: 24px 24px 30px 24px;
  max-width: 500px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
}

.modal-input-box {
  margin-bottom: 24px;
}

.modal-input-box .form-group .form-control input {
  padding: 5.5px 15px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* end add user modal css */
/* start delete user modal css */

.user-circle-img {
  width: 45px;
  height: 45px;
  object-fit: contain;
  margin: auto;
  display: flex;
}

.modal-delete .delete-modal-inner-main {
  /* padding: 40px 24px 40px 24px; */
  padding: 20px 20px 25px 20px;
  max-width: 500px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
}

.delete-modal-inner-main .delete-modal-title {
  color: #111827;
  font-size: 28px;
  line-height: 30px;
  font-family: "inter-medium", sans-serif;
  text-align: center;
  margin: 20px 0px 16px;
}

.delete-modal-inner-main .delete-modal-para {
  color: #6b7280;
  text-align: center;
  max-width: 304px;
  width: 100%;
  margin: auto;
  font-family: "inter-medium", sans-serif;
  font-size: 15px;
}

.delete-modal-btn-flex {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}

.delete-modal-btn-flex .modal-btn {
  border-radius: 4px;
  border: 1px solid #d1d5db;
  max-width: 100px;
  width: 100%;
  padding: 8px;
  text-transform: capitalize;
}

.delete-modal-btn-flex .modal-cancel-btn {
  background: #fff;
  border: 1px solid #eaac35;
  color: #eaac35;
  border-radius: 10px;
  margin-right: 20px;
}

.delete-modal-btn-flex .modal-delete-btn,
.delete-modal-btn-flex .modal-delete-btn:hover {
  color: #ffffff;
  background: #eaac35;
  border-radius: 10px;
}

.MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(17 24 39 / 60%);
}

/* end delete user modal css */

.incre-btn-main .inc-dic-btn,
.incre-btn-main .inc-dic-btn:hover {
  background-color: #003d90;
  height: 34px;
  min-width: 34px;
  color: #fff;
  line-height: 23px;
  font-size: 17px;
  margin-top: 15px;
  border-radius: 5px;
  padding-bottom: 8px;
}

.incre-btn-main .inc-dic-btn:first-child {
  margin-right: 15px;
}

.incre-btn-main .Mui-disabled {
  color: #c5c1c1 !important;
}

.Mui-error {
  margin-left: 0px !important;
  margin-top: 0px !important;
}

.action-menu-icon {
  font-size: 20px !important;
}

.action-text {
  font-family: "inter-regular", sans-serif !important;
  color: #122b45 !important;
  font-size: 13px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 23-5 */
.filter-datepicker .MuiOutlinedInput-input {
  border: 0 !important;
}

.filter-date-overflow>.MuiStack-root {
  overflow: hidden;
}

.pdf-design.MuiBox-root.css-0 {
  font-family: "inter-medium", sans-serif;
  font-size: 14px;
  background: transparent;
  color: #003d90;
  padding: 8px;
  text-align: center;
  border-radius: 5px;
  border: 1px dashed #003d90;
}

.pdf-upload-design {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.filter-close-btn img {
  height: 23px;
  width: 23px;
}

.modal-close-button {
  height: 23px;
  width: 23px;
}

/* 9-6 */
/* / select compny start / */
.select-compny-cad-main {
  max-width: 1118px !important;
  width: 100%;
}

.select-card-main {
  background: #fff;
  padding: 50px;
  box-shadow: 0px 3px 30px #0000000d;
  border: 1px solid #e2e2e2;
  border-radius: 15px;
}

.title-main .select-page-title {
  color: #000000;
  font-size: 30px;
  line-height: 36px;
  font-family: "inter-bold", sans-serif;
  text-align: center;
}

.compny-img {
  border: 2px solid #d9d9d9;
  border-radius: 15px;
  padding: 20.5px;
  background: white;
  cursor: pointer;
}

.compny-img-inner {
  width: 196px;
  height: 158px;
  border-radius: 10px;
}

.set-select-img {
  text-align: center;
}

.select-compny-text {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-family: "inter-semibold", sans-serif;
  color: #122b45;
}

.select-text-set {
  margin-top: 20px;
}

.select-img-box {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.select-img-main {
  margin: 9px;
  width: 241px;
}

.mb-41 {
  margin-bottom: 41px;
}

.select-check-box {
  text-align: center;
  justify-content: center;
  margin-top: 15px;
}

.select-check-box svg {
  width: 30px;
  height: 30px;
}

.select-check-box .MuiCheckbox-colorPrimary {
  padding-top: 16px 0px 0px !important;
}

.select-modal-box {
  max-width: 551px !important;
  width: 100% !important;
  border: 1px solid #707070 !important;
  padding: 0px !important;
  border-radius: 20px !important;
  outline: none;
  cursor: pointer;
}

.select-modal-header {
  background: #003d90;
  padding: 15px 20px;

  border-radius: 19px 19px 0px 0px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.select-modal-header-text {
  font-size: 18px;
  color: white;
  line-height: 20px;
  font-family: "inter-semibold", sans-serif;
}

.select-modal-img-box {
  padding: 40px 50px;
}

.select-text-set-modal {
  margin-top: 9px;
}

.modal-compny-img-inner {
  width: 94px;
  height: 101px;
  border-radius: 10px;
}

.modal-compny-img {
  border: 2px solid #d9d9d9;
  border-radius: 15px;
  padding: 13px 14px;
  background: white;
}

.modal-select-img-box {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.select-img-main-modal {
  margin: 14px;
  width: 130px;
}

.modal-set-select-img {
  text-align: center;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
}

.modal-select-compny-text {
  text-align: center;
  font-size: 15px;
  line-height: 19px;
  font-family: "inter-semibold", sans-serif;
  color: #122b45;
}

.set-modal-close-icon {
  height: 30px;
  width: 30px;
}

.modal-close-img {
  height: 16px;
  width: 16px;
}

.modal-close-icon-small {
  height: 20px;
  width: 20px;
}

.modal-icon-btn {
  margin-top: 11px !important;
}

.res-btn-close {
  min-width: auto !important;
  padding: 0px !important;
}

.modal-compny-img .active {
  border: 1px solid #1f65db;
}

.select-img-main-modal.active .modal-compny-img {
  border: 2px solid #1f65db;
}

.select-img-main-modal.active .modal-icon-btn .modal-close-icon-small-grey {
  display: none;
}

.select-img-main-modal.active .modal-icon-btn .modal-close-icon-small-blue {
  display: flex;
  margin: auto;
}

.select-img-main-modal .modal-icon-btn .modal-close-icon-small-grey {
  display: flex;
  margin: auto;
}

.select-img-main-modal .modal-icon-btn .modal-close-icon-small-blue {
  display: none;
}

.select-img-main.active .compny-img {
  border: 2px solid #1f65db;
}

.select-img-main.active .select-check-box .modal-close-icon-small-blue {
  display: block;
  margin: auto;
}

.select-img-main.active .select-check-box .modal-close-icon-small-grey {
  display: none;
}

.select-img-main .select-check-box .modal-close-icon-small-grey {
  display: block;
  margin: auto;
}

.select-img-main .select-check-box .modal-close-icon-small-blue {
  display: none;
}

/* / select compny end / */
.barge-dashboard-box {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #dfe0e1;
  border-radius: 10px;
}

.barge-common-box {
  background-color: #f0f6ff;
  padding: 20px;
  /* border: 1px solid #DFE0E1; */
  border-radius: 10px;
  border: 1px solid #d6e5ff;
}

.tab-content-box {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;
}

.tab-button-box .MuiTabs-root {
  /* background-color: #f0f6ff; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.MuiTab-root.Mui-selected.tab-button {
  /* background-color: #a6ccff; */
  color: inherit;
  border-radius: 10px 10px 0 0;
}

.MuiTab-root.tab-button {
  flex: 1;
  font-family: "inter-semibold", sans-serif;
}

.table-design-main {
  background-color: #f0f3f8;
}

/* .page-content-box {
  margin-top: 30px;
  margin-bottom: 30px;
} */
.dashboard-containt-main {
  padding: 60px 30px 0px;
}

.filter-date-overflow .MuiStack-root {
  width: 100%;
}

.barge-dashboard-page {
  margin-top: 30px;
  margin-bottom: 30px;
}

.one-line-table tr th,
.one-line-table tr td {
  white-space: nowrap;
}

.table-design-main tr td:last-child button.MuiIconButton-sizeMedium {
  padding: 3.5px;
}

.input-design-div .MuiInputBase-fullWidth {
  /* border-radius: 10px; */
  border-radius: 4px;
  height: 35px;
  line-height: 25px;
}

.input-design-div .set-date-picker .MuiInputBase-input {
  background: transparent;
}

/* .table-design-main tr th:last-child, .table-design-main tr td:last-child {
  width: 50px;
} */
.white-login-box.MuiBox-root.css-0 {
  background: #fff;
  padding: 50px;
  border-radius: 15px;
}

.bluebox-text p {
  color: #122b45;
}

.login-input-design-div .MuiFilledInput-underline,
.login-input-design-div .MuiFilledInput-underline:hover,
.login-input-design-div .MuiFilledInput-underline.Mui-focused {
  height: 36px;
  border: 1px solid #d4d9e1;
}

.logo-set2 {
  margin-bottom: 10px;
}

/* / 14/6/23 start css / */

.profile-image-center {
  text-align: center;
}

.input-design-div-set-back .MuiInputBase-input {
  background-color: transparent !important;
}

.change-password-icon .admin-input-design .MuiInputAdornment-filled button svg {
  width: 18px;
  height: 18px;
}

.change-password-icon .MuiInputBase-input {
  font-size: 14px !important;
}

.change-password-text {
  margin-bottom: 20px;
}

.input-design-div .password-set {
  margin-bottom: 20px !important;
  margin-top: 0px !important;
}

.input-design-div .password-set-box {
  margin-top: 0px !important;
}

.input-design-div .label-set {
  margin-top: 0px !important;
}

.input-label-new {
  background: #f0f3f8 !important;
}

/* / 14/6/23 start css / */

body .MuiPopover-paper {
  box-shadow: none !important;
  border-radius: 8px;
  border: 1px solid #e1dddd;
}

/* width */
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddecff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #767676;
}

/* table start */
.table-design-main tr th {
  background: #ddecff;
  /* padding: 20px !important; */
  padding: 15px 9px !important;
  white-space: nowrap;
}

.dashboard-containt-main {
  padding: 60px 30px 0px;
  background: #f0f3f8 !important;
}

.table-design-main {
  background: #f0f3f8;
  border-collapse: separate !important;
  border-spacing: 0px 8px !important;
  border-radius: 0px !important;
}

.table-design-main tr th:first-child,
.table-design-main tr td:first-child {
  padding-left: 20px !important;
}

.table-design-main tr th:last-child,
.table-design-main tr td:last-child {
  padding-right: 20px !important;
}

.barge-common-box-table {
  background-color: #f0f3f8;
  padding: 0px !important;

  border-radius: 10px;
}

.table-design-main tr td:last-child {
  border-radius: 0 10px 10px 0 !important;
}

.table-design-main tr td:first-child {
  border-radius: 10px 0 0 10px !important;
}

.table-design-main tr td {
  background: #fff;
  display: table-cell;
}

.table-design-main tr th:first-child,
.table-design-main tr td:first-child {
  border-radius: 10px 0px 0px 10px;
}

.table-design-main tr th:last-child,
.table-design-main tr td:last-child {
  border-radius: 0 10px 10px 0 !important;
}

.profile-inner .dashboard-item {
  background: transparent;
}

.set-textarea-box {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px 16px;
  resize: none;
}

.set-profile-set {
  font-size: 14px;
  font-family: "inter-bold", sans-serif;
  line-height: 20px;
  color: #122b45;
  padding: 0px 8px;
  text-transform: capitalize;
  display: flex;
}

.set-admin-header-profile-icon {
  width: 33px !important;
  height: 33px !important;
}

.set-profile-inner {
  font-size: 8px;
  line-height: 10px;
  color: #959595;
  padding: 0px 8px;
  font-family: "inter-regular", sans-serif;
}

.new-menu-set-main .MuiMenu-paper {
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  top: 51px !important;
  width: 200px;
}

.pink-btn-menu {
  background: #ffb8d0 !important;
  text-align: center !important;
  justify-content: center !important;
  border-radius: 6px !important;
  margin: 0px 16px !important;
}

.drop-header-btn-set {
  padding: 0px 0px 6px !important;
}

.menu-set-main ul {
  padding-top: 9px !important;
  padding-bottom: 16px !important;
}

.menu-set-main .MuiMenu-paper {
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  top: 51px !important;

  width: 200px;
}

/* .css-2hzjrb-MuiInputBase-input-MuiFilledInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(0 0 0) !important;
} */

.table-design-main tr td:first-child {
  padding-left: 20px !important;
  white-space: nowrap;
}

.input-design-div fieldset {
  border-color: #d6e5ff;
}

.invoice-table tr td {
  white-space: nowrap;
}

.paiddot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: green;
  border-radius: 50%;
  margin-right: 10px;
}

.unpaiddot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 50%;
  margin-right: 10px;
}

.MuiPopover-root .MuiPaper-root .MuiList-root li.Mui-selected {
  background: #a6ccff;
}

.input-design-div .MuiOutlinedInput-root:hover fieldset {
  border-color: #d6e5ff;
  border-width: 1px;
}

.MuiOutlinedInput-root .MuiSelect-select .multiselect-item {
  display: inline-flex;
  background: #c9e0ff;
  font-size: 10px;
  padding: 0 8px;
  border-radius: 3px;
  line-height: 2;
  font-family: "poppins-semibold", sans-serif;
  color: #003d90;
}

.total-service {
  display: flex;
  justify-content: space-between;
}

.service-right .payment-row {
  display: flex;
}

.service-right .payment-row .amount-title {
  width: 180px;
  font-family: "inter-medium", sans-serif;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 25px;
}

.service-right .payment-row .amount-number {
  font-family: "poppins-regular", sans-serif;
  font-size: 14px;
  line-height: 25px;
  text-align: right;
  width: 140px;
}

.service-total-div {
  border-bottom: 1px solid #d6e5ff;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.addinvoice-table tr th:first-child,
.addinvoice-table tr td:first-child {
  min-width: 143px;
  max-width: 143px;
}

.addinvoice-table tr th:nth-child(2),
.addinvoice-table tr td:nth-child(2) {
  /* max-width: 200px !important;
  min-width: 200px !important; */
  max-width: 270px !important;
  min-width: 270px !important;
}

.addinvoice-table tr th:nth-child(3),
.addinvoice-table tr td:nth-child(3) {
  min-width: 100px !important;
  max-width: 100px !important;
}

.addinvoice-table tr th:nth-child(4),
.addinvoice-table tr td:nth-child(4) {
  min-width: 150px !important;
}

.addinvoice-table tr th:nth-child(5),
.addinvoice-table tr td:nth-child(5) {
  min-width: 140px !important;
}

.addinvoice-table tr th:last-child,
.addinvoice-table tr td:last-child {
  /* max-width: 310px !important;
  min-width: 310px !important; */
  max-width: 240px !important;
  min-width: 240px !important;
}

.addinvoice-table tr th.last-auto:last-child,
.addinvoice-table tr td.last-auto:last-child {
  max-width: inherit !important;
  min-width: inherit !important;
}

.addinvoice-table tr td.last-auto .incre-btn-main {
  display: flex;
}

.addinvoice-table tr td.last-auto button,
.addinvoice-table tr td.last-auto button:hover {
  margin-top: 0;
}

.addinvoice-table tr td.taxable-qty-adjust-input .input-design-div.with-border input {
  /* width: 140px; */
  padding: 3px 10px !important;
}

.signleinvoices-view tr th:last-child,
.signleinvoices-view tr td:last-child {
  max-width: 281px !important;
  min-width: 281px !important;
}

.addinvoice-table tr td {
  vertical-align: top;
}

.addinvoice-table tr td {
  padding: 11px 9px !important;
}

.service-main-total .amount-title {
  font-family: "inter-semibold", sans-serif !important;
}

.service-main-total .amount-number {
  /* font-family: 'inter-semibold' !important; */
  font-family: "poppins-semibold", sans-serif !important;
}

.invoice_number {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invoice_number_align {
  margin-left: 10px !important;
  font-size: 16px !important;
}

.party_btn {
  display: flex !important;
  justify-content: flex-start;
  /* margin-right: "-20px"; */
  /* margin-left: 0px !important; */
}

.party_submit_btn {
  margin-left: 0px !important;
}

.input-textarea textarea {
  border: 1px solid #ccc;
  /* border-radius: 8px; */
  border-radius: 4px;
  background-color: #fff;
  font-family: "inter-regular", sans-serif;
  font-size: 14px;
  padding: 10px 10px !important;
  outline: none;
  resize: none;
}

.ml-0 {
  margin-left: 0 !important;
}

/* 13/7/2023 start */

.set-list-button {
  min-width: auto !important;
  padding: 0px !important;
  margin-bottom: 0px !important;
}

.set-list-button svg {
  width: 12px !important;
  height: 12px !important;
}

.grey-button-list button {
  margin-right: 10px;
}

/* .invoice-table-set tr th:first-child {
  padding-left: 40px !important;
} */

.tree-analysis-main .tree-list-analysis {
  padding: 0px;
}

.tree-analysis-main .tree-list-analysis .tree-listitem-analysis {
  padding: 0px;
}

.tree-analysis-main .tree-list-analysis {
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  margin: 0px -5px;
}

.tree-analysis-main .tree-list-analysis .tree-listitem-analysis {
  padding: 0px 5px;
  width: calc(150% / 3);
  margin-bottom: 10px;
  cursor: pointer;
}

.tree-analysis-main .tree-list-analysis .tree-listitem-analysis .box-card-tree-analysis {
  background: white;
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ddecff;
  border-radius: 10px;
}

.box-card-tree-analysis .title-tree-list {
  font-family: "poppins-semibold", sans-serif;
  /* font-size: 17px; */
  font-size: 13px;
  line-height: 21px;
  margin-bottom: 5px;
  color: #003d90;
  word-break: break-word;
}

.box-card-tree-analysis .price-tree-list {
  font-family: "poppins-regular", sans-serif;
  font-size: 13px;
  margin-bottom: 0px;
  color: #000;
}

.tree-analysis-main {
  height: 201px;
  padding-right: 10px;
  margin-right: -9px;
  overflow: auto;
}

/* width */
/* .tree-analysis-main::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 10px;
} */

/* Track */
/* .tree-analysis-main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 2px;
} */

/* Handle */
/* .tree-analysis-main::-webkit-scrollbar-thumb {
  background: #7a808b;
  border-radius: 2px;
} */

/* Handle on hover */
/* .tree-analysis-main::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
  border-radius: 10px;
} */

.edit-btn-bank {
  display: flex;
  background: #e1e1e1;
  padding: 3px;
  border-radius: 5px;
  margin-right: 9px;
}

.edit-btn-bank svg {
  fill: transparent;
  stroke: black;
  stroke-width: 2px;
}

.edit-flex-btn {
  display: flex;
  align-items: center;
}

.flex-edittext-color {
  color: #000;
}

.set-list-button:hover {
  background-color: transparent;
}

.table-design-main .invoice-view-cursor {
  cursor: pointer !important;
}

.invoice-amount {
  font-size: 11px !important;
}

.barge-search-main .search {
  margin-left: auto;
  margin-right: 0px;
  background: white;
}

.card-flex-invoice {
  width: 100%;
}

.card-flex-invoice .card-graph-content-inner {
  margin-right: 0px;
  display: flex;
  align-items: center;
}

.card-graph-content-inner .invoice-col-1 {
  max-width: 120px;
  flex: 0 0 120px;
  width: 100%;
  margin-right: 15px;
}

.card-graph-content-inner .invoice-col-2 {
  max-width: 30px;
  flex: 0 0 30px;
  width: 100%;
  margin-right: 15px;
}

.card-graph-content-inner .invoice-col-3 {
  flex: 1;
}

.bank-quanty-flex {
  display: flex;
  align-items: center;
}

.bank-quanty-flex {
  max-width: 120px;
  width: 100%;
  flex: 0 0 120px;
  margin-right: 20px;
}

.banker-detail-unit {
  flex: 1;
}

.pagination-count {
  display: flex;
}

.pagination-loader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;
}

.banker-flex-justify {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.input-label-invoice {
  color: #122b45;
  /* font-size: 12px; */
  font-size: 11px;
  line-height: 15px;
  font-family: "inter-semibold", sans-serif;
  padding-bottom: 8px !important;
  display: flex;
}

.input-design-div .invoice-select-drop .MuiInputBase-root,
.input-design-div .invoice-particulars-select-drop .MuiInputBase-root {
  padding: 0px !important;
}

.input-design-div .invoice-particulars-select-drop .MuiInputBase-input {
  padding-right: 50px !important;
}

.model-close-icon {
  display: flex !important;
  justify-content: end !important;
  margin-left: auto;
  cursor: pointer;
}

.activity-log-header {
  /* border: 5px solid red !important; */
  border-bottom: 5px solid #a6ccff !important;

  /* border-bottom: 5px solid #003d90 !important; */
  border-radius: 2px !important;
  width: fit-content !important;
}

.empty-record-align {
  text-align: center !important;
}

/* bug issue fixed css */

.rate-table-design tr th:nth-child(1),
.rate-table-design tr td:nth-child(1) {
  min-width: 50%;
  max-width: 50%;
  width: 50%;
}

.rate-table-design tr th:nth-child(2),
.rate-table-design tr td:nth-child(2) {
  min-width: 15%;
  width: 15%;
  max-width: 15%;
}

.rate-table-design tr th:nth-child(3),
.rate-table-design tr td:nth-child(3) {
  min-width: 15%;
  width: 15%;
  max-width: 15%;
}

/* .res-button-width .view-invoice-btn{
   margin: 0px 7px;
} */

.invoice-details .back-invoice-btn {
  margin-right: 10px;
  padding: 0;
  min-width: unset;
  color: #003d90;
}

.invoice-details .save-btn .edit-invoice-btn svg {
  width: 20px;
  height: 20px;
}

.invoice-details .save-btn .edit-invoice-btn,
.invoice-details .save-btn .edit-invoice-btn:hover {
  border-radius: 50%;
  width: 33px;
  height: 33px;
  min-width: unset;
}

.invoice-details .invoice-detail-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.invoice-details .invoice-total {
  float: right;
  margin-top: 5px;
}

.invoice-details .invoice-total .input-label {
  justify-content: flex-end;
}

.invoice-details .invoice-total-amount {
  color: #122b45;
  font-size: 20px;
  line-height: 26px;
  font-family: "inter-semibold", sans-serif;
}

.invoice-details .invoice-back-title {
  display: flex;
  align-items: center;
}

.invoice-details .invoice-back-title .invoice-title {
  font-family: "inter-semibold", sans-serif;
  font-size: 20px;
  line-height: 26px;
  color: #003d90;
}

.res-button-width .view-invoice-btn:first-child {
  margin-left: 0px;
}

.res-button-width .view-invoice-btn:last-child {
  margin-right: 0px;
}

.flex-column-res {
  margin-bottom: 15px;
}

.tab-comman-padding {
  padding: 20px 0px;
}

.invoice-activity-log th {
  /* font-family: "inter-bold";
   */
  font-family: "inter-semibold", sans-serif;
}

.remark-text {
  text-transform: none !important;
}

.order-active-log-header {
  margin-top: 15px !important;
}

.challan-status {
  text-transform: none !important;
}

.reject-btn-align {
  margin-left: 7px !important;
}

.challan-img-model {
  width: 50px !important;
}

.challan-img {
  width: 100% !important;
}

.challan-icon {
  padding-right: 12px !important;
  font-size: 30px !important;
}

.challan-icon-align {
  display: flex;
}

.status-success {
  color: #008000 !important;
}

.status-error {
  color: #ff0000 !important;
  font-weight: bold;
}

.inquiry-list {
  cursor: pointer;
}

.delete-modal-btn-flex .modal-inquiry-success-btn {
  background: #008000 !important;
  border: 1px solid #eaac35;
  color: #fff;
  border-radius: 10px;
  margin-right: 20px;
  font-family: "inter-medium", sans-serif;
  font-size: 15px;
}

.delete-modal-btn-flex .modal-inquiry-error-btn {
  background: #ff0000 !important;
  border: 1px solid #eaac35;
  color: #fff;
  border-radius: 10px;
  margin-right: 20px;
  font-family: "inter-medium", sans-serif;
  font-size: 15px;
}

.all-select-label {
  padding: 0px !important;
}

.all-select-label li {
  background: transparent !important;
}

.nomination-no-align {
  margin-top: 8px !important;
}

.user-img {
  width: 100%;
  max-width: 70px;
}

.user-img-main {
  width: 70px;
}

.user-main-img {
  width: 70px !important;
}

.challan-icon-hidden {
  opacity: 0;
}

/* .table-design-main tr td:last-child button.MuiIconButton-sizeMedium svg {
  margin-right: 15px;
} */

.table-design-main tr td:last-child button.MuiIconButton-sizeMedium:hover {
  background-color: transparent !important;
}

.table-design-main tr th {
  font-size: 15px;
}

.purchase-modal-box {
  min-width: 300px;
  max-width: 300px;
}

.remark-box {
  height: 96px !important;
  overflow: auto !important;
}

.cancel-order-btn {
  margin-left: 10px !important;
}

.user-img-open-align {
  margin: -10px;
}

.box-text .page-not-found {
  font-size: 28px !important;
  line-height: 36px !important;
  font-family: "inter-bold", sans-serif !important;
}

.user-no-img {
  text-transform: none;
  color: black;
}

.modal-delete .barge-wise-modal-inner-main {
  padding: 20px 24px 20px 24px;
  max-width: 600px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
}

.barge-wise-modal-inner-main .barge-modal-title {
  color: #111827;
  font-size: 23px;
  line-height: 42px;
  font-family: "inter-medium", sans-serif;
  text-align: center;
  margin: 5px 0px 16px;
}

.invoice-activity-log td p {
  font-family: "inter-regular", sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.set-sidebar-menu-drop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.set-sidebar-menu-drop .drop-main {
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  font-family: "poppins-light", sans-serif;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.set-sidebar-menu-drop .drop-main img {
  padding-right: 15px;
}

.expandmore-icon {
  fill: white !important;
}

.expandless-icon {
  fill: white !important;
}

.admin-sidebar-link-box {
  width: 100%;
  text-decoration: none !important;
}

.admin-sidebar-link-sub {
  margin-left: 0px !important;
}

.invoice-file-empty-data {
  text-align: center;
}

/* .admin-submenu-listitem-main{
  padding-top: 15px !important;
    padding-bottom: 15px !important;
} */
.cate-table-main-box table tr th {
  position: sticky;
  top: 0;
  text-align: left;
}

.cate-table-main-box .MuiPaper-elevation {
  height: 203px;
  background: #f0f6ff;
  border-radius: 9px;
}

.cate-table-main-box table tr:first-child td {
  padding-top: 11px;
}

.admin-submenu-listitem-main {
  padding: 15px 20px !important;
  /* padding-top: 15px !important;
  padding-bottom: 15px !important; */
}

.table-design-main tr td:last-child {
  border-radius: 0px 10px 10px 0px !important;
}

.MuiPaper-elevation table.table-design-main .no-data-in-list {
  border-radius: 10px 10px 10px 10px !important;
  text-align: center;
}

.custom_form_control-new.Mui-focused legend span {
  display: none;
}

.custom_form_control-new p {
  margin-top: 3px !important;
}

.inquiry-submit-btn {
  margin-top: 30px;
}

.remove-name label {
  display: none;
}

div[style*="pointer-events: none; color: rgba(130, 130, 130, 0.62);"] {
  display: none;
}

.expense-type .radio-button-options {
  display: inherit;
  gap: 10px;
  /* Adjust the gap between options as needed */
}

/* .expense-add-main .MuiDialog-paper{
min-width: 650px !important;
} */
.add-ex-modal-btn {
  justify-content: flex-end;
}

/* Green Icon */
.green-icon {
  color: green;
}

/* Red Icon */
.red-icon {
  color: red;
}

.enquiry-vessel-autocomplete {
  width: 100% !important;
}

.banker-detail-unit-box-set {
  overflow: auto;
  height: 66px;
}

.input-design-div textarea:focus-visible {
  outline: none !important;
}

.input-placeholder-box::placeholder {
  color: #bec4cb;
}

.input-placeholder-box-set {
  height: 67px;
  resize: none;
  border: 1px solid #d6e5ff;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  font-family: "inter-regular", sans-serif;
}

.pdf-input a {
  text-decoration: none;
}

.challan-iseditable-main .challan-iseditable {
  margin-top: 20px;
  margin-left: 20px;
}

.challan-iseditable {
  margin-top: 5px;
}

.challan-table tr td:nth-child(4) {
  margin-top: 10px;
}

table.challan-table-set tr td p {
  color: #122b45;
  font-size: 13px;
  line-height: 15px;
  font-family: "inter-semibold", sans-serif;
  opacity: 0.6;
}

table.invoice-activity-log tr td p {
  color: #122b45;
  font-size: 13px;
  line-height: 15px;
  font-family: "inter-semibold", sans-serif;
  opacity: 0.6;
  padding: 8px 0;
}

.challan-table-font th {
  /* font-family: "inter-bold"; */
  font-family: "inter-semibold", sans-serif;
  background-color: #ddecff;
  border: 0px;
}

.signleinvoices-view-set tr td label {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;
  font-family: "inter-semibold", sans-serif;
  padding-bottom: 8px !important;
  padding-top: 8px !important;
  display: flex;
  opacity: 0.6;
}

.signleinvoices-view-set tr th:nth-child(2),
.signleinvoices-view-set tr td:nth-child(2) {
  max-width: 322px !important;
  min-width: 322px !important;
}

.expensedetails-table tr td p {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;
  font-family: "inter-semibold", sans-serif;
  padding-bottom: 8px !important;
  display: flex;
  opacity: 0.6;
}

.activity-log-table tr th {
  font-family: "inter-bold", sans-serif;
}

.activity-log-table-set tr th {
  font-family: "inter-bold", sans-serif;
}

.activity-log-table tr td p {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;
  font-family: "inter-semibold", sans-serif;
  padding-bottom: 8px !important;
  display: flex;
  opacity: 0.6;
}

.supply-table tr td:nth-child(20) {
  text-align: center;
}

.table-design-main .print-expense {
  text-transform: none;
}

.print-expense-btn-main .print-expense {
  text-transform: none;
  min-width: 0 !important;
  padding: 0px !important;
}

.admin-uppercase-role {
  text-transform: uppercase;
}

.picker-box-set .Mui-error {
  margin-top: 0px !important;
}

/* .expense-table tr td:nth-child(10) {
  text-transform: none;
} */
.edit-icon-set path {
  fill: #1976d2 !important;
}

.print-btn-expense-main .print-btn,
.print-btn-expense-main .print-btn:hover {
  background-color: orange;
  color: white;
  text-transform: none;
  border-bottom: 3px;
}

.html-print-data {
  display: none;
}

table.print-table-design tr td,
table.print-table-design tr th {
  vertical-align: baseline;
  font-size: 15px;
  border-collapse: collapse;
  border: 2px solid black;
  padding: 5px;
}

.admin-uppercase-role .common-button button,
.admin-uppercase-role .common-button button:hover {
  font-size: 12px;
  font-family: "inter-semibold", sans-serif;
  padding: 7px 13px 6px 13px;
  line-height: 19px;
  box-shadow: 0px 3px 4px #ccc4b3;
  letter-spacing: 0px;
  border-radius: 4px;
  text-transform: uppercase;
}

.order-active-table-set {
  width: 100%;
}

.main-pending-enquiry .modal-circle-main {
  cursor: pointer !important;
}

.user-circle-icon {
  margin-left: auto !important;
  width: 30px !important;
  height: 30px !important;
}

.main-pending-enquiry {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-circle-icon-btn {
  padding: 0px !important;
  background: transparent !important;
  min-width: initial !important;
}

.admin-uppercase-role.multiselect-item {
  text-transform: uppercase;
}

.admin-uppercase-role .input-design-div.with-border input,
.admin-uppercase-role .set-textarea-box-top .MuiInputBase-formControl {
  text-transform: uppercase;
}

.admin-uppercase-role .MuiMenu-list li {
  text-transform: uppercase;
}

.enquiry-vessel-autocomplete {
  text-transform: uppercase;
}

.admin-uppercase-role .input-placeholder-box-set {
  text-transform: uppercase;
}

.admin-uppercase-role .print-expense-btn-main .print-expense {
  text-transform: uppercase;
  min-width: 0 !important;
  padding: 0px !important;
}

.barge-common-box-set {
  height: 100%;
  min-height: 100vh;
  border-radius: 0px !important;
}

.input-design-div3 .MuiInputBase-fullWidth {
  height: 35px;
  background: #fff;
}

.input-design-div3 .MuiInputBase-input {
  padding: 0px 10px !important;
}

.input-design-div3 .MuiInputBase-fullWidth:hover fieldset {
  border-color: #dfe0e1 !important;
}

.input-design-div3 .MuiInputBase-fullWidth.Mui-focused fieldset {
  border-color: #dfe0e1;
  border-width: 1px;
}

.addinvoice-table-add tr th:first-child,
.addinvoice-table-add tr td:first-child {
  min-width: 150px !important;
  max-width: 150px !important;
}

.addinvoice-table-add tr th:nth-child(2),
.addinvoice-table-add tr td:nth-child(2) {
  min-width: 191px !important;
  max-width: 191px !important;
}

.addinvoice-table-add tr th:nth-child(4),
.addinvoice-table-add tr td:nth-child(4) {
  min-width: 130px !important;
  max-width: 130px !important;
}

.addinvoice-table-add tr th:nth-child(5),
.addinvoice-table-add tr td:nth-child(5) {
  min-width: 123px !important;
  max-width: 123px !important;
}

.addinvoice-table-add tr th:last-child,
.addinvoice-table-add tr td:last-child {
  max-width: 161px !important;
  min-width: 161px !important;
}

.item-dropdown {
  font-family: "inter-regular", sans-serif !important;
  font-size: 14px !important;
}

.role-masters tr th:first-child,
.role-masters tr td:first-child {
  padding-left: 20px !important;
}

.role-masters tr th:last-child,
.role-masters tr td:last-child {
  width: 1% !important;
}

.trader-list tr th:first-child,
.trader-list tr td:first-child {
  padding-left: 20px !important;
}

.tanker-list tr th:first-child,
.tanker-list tr td:first-child {
  padding-left: 20px !important;
}

.expenses-table tr th:first-child,
.expenses-table tr td:first-child {
  padding-left: 20px !important;
}

.body-no-class .admin-panel-content {
  padding: 20px 20px 20px 20px;
}

.role-design label span {
  font-family: "inter-regular", sans-serif;
  font-size: 13px;
  color: #122b45;
  line-height: 15px;
  white-space: break-spaces;
}

.input-design-div.role-design {
  background: #dce9ff;
  border-radius: 15px;
  padding: 0 15px;
}

.main-permission-box {
  margin-top: 35px;
}

.loading-purchase {
  text-align: center !important;
}

.res-button-add-set {
  justify-content: flex-end;
  display: flex;
}

.set-active-btn {
  margin: 10px 20px;
}

.card-graph-content-inner-new .invoice-col-1 {
  max-width: 164px !important;
  flex: 0 0 164px !important;
  width: 100%;
  margin-right: 3px !important;
}

.new-total-invoice {
  border-bottom: none !important;
}

.card-graph-content-inner-new .card-price {
  text-align: end;
}

.eye-btn-set {
  padding: 0px 7px 0px 3px !important;
}

/* / noti /  */
.notification-img {
  display: flex;
  margin-right: 15px;
}

.notification-main-box {
  background: white;
  padding: 15px 9px !important;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid #d6e5ff;
  position: relative;
}

.img-notification {
  height: 40px;
  width: 40px;
  object-fit: contain;
  border-radius: 50px;
}

.notification-box {
  display: flex;
  align-items: center;
}

.notification-text {
  font-size: 14px;
  line-height: 21px;
  color: black;

  font-family: "inter-bold", sans-serif;
}

.notification-sub-text {
  font-size: 12px;
  line-height: 21px;
  color: #003d90;
  font-family: "poppins-light", sans-serif;
}

.notification-time {
  font-size: 12px;
  line-height: 21px;
  color: #003d90;
  font-family: "poppins-light", sans-serif;
}

.set-edit-timing-box {
  border: 1px solid #d6e5ff;
  margin-top: 20px !important;
  border-radius: 10px;
}

.placeholder-set-text-box li {
  font-size: 14px;
  line-height: 25px;
  font-family: "inter-regular", sans-serif;
}

.select-label-font {
  font-size: 14px !important;
}

.admin-uppercase-role .user-password-field input {
  text-transform: none !important;
}

.admin-uppercase-role .user-email-field input {
  text-transform: none !important;
}

.expense-model-main-box-set {
  width: 100%;
  min-width: 500px !important;
  max-width: 500px !important;
}

.update-model-main-box-set {
  width: 100%;
  min-width: 500px !important;
  max-width: 500px !important;
}

.modal-update-main {
  height: 230px;
}

body .MuiDialog-paper {
  overflow-y: initial;
}

.craft-assign-model {
  height: 60vh;
}

.invoice-details .flex-direction-item {
  flex-direction: column;
  align-items: start;
}

.signleinvoices-view-set tr th:nth-child(1),
.signleinvoices-view-set tr td:nth-child(1) {
  width: 15%;
}

.curser-ponter .cursor-pointer-link {
  cursor: pointer !important;
}

.curser-ponter .cursor-pointer-link:hover {
  cursor: pointer !important;
}

.curser-ponter-new {
  cursor: pointer !important;
}

/* .document-box-display {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.invoice-details .flex-direction-item .download-pdf-pd {
  align-items: start;
  padding: 5px 10px;
  text-align: start;
  text-transform: unset !important;
}

.invoice-details .flex-direction-item .Wharfage-pdf {
  padding-bottom: 0px !important;
}

.top-right-notification {
  position: absolute;
  right: 10px;
  top: 10px;
}

.order-loading-details .order-loading-content {
  width: 100%;
  margin-bottom: 0px;
}

.title-header-flex.order-loading-details {
  margin-bottom: 0px;
}

.pd-loading-details {
  padding: 10px 20px;
}

.loading-tanker-hide {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.loading-tanker-hide .hide-loading {
  border: 1px solid #1976d2;
  border-radius: 5px;
  padding: 6px 10px;
  text-decoration: none;
  cursor: pointer;
}

.datetime {
  display: flex;
  /* justify-content: center;
  align-items: center;
  margin: 10px 0px 10px; */
}

.datetime span {
  font-size: 13px;
  line-height: 18px;
  font-family: "inter-bold", sans-serif;
}

.title-header-flex .invoice-date-time {
  justify-content: flex-start;
  align-items: flex-start;
}

/* .order-doc-delete-icon {
  margin-right: -56px;
  cursor: pointer;
} */

/* .order-doc-eye-icon {
  margin-top: 5px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
} */

/* .eye-icon-btn {
  padding: 1px !important;
  padding-right: 0px !important;
} */

/* .order-doc-delete-btn {
  padding: 1px !important;
  padding-right: 0px !important;
} */

/* Task Management */

.filter-input-design .invoice-select-drop .MuiInputBase-root,
.filter-input-design .invoice-particulars-select-drop .MuiInputBase-root {
  background-color: transparent;
  box-shadow: none;
}

.filter-input-design .MuiInputBase-input {
  background-color: transparent;
}

.filter-input-design.with-border input {
  background-color: transparent;
}

.comments-list-main {
  margin-top: 10px;
  width: 100%;
  max-height: 250px;
  height: 100%;
  overflow: auto;
}

.comments-box-main {
  margin: 15px 0;
  max-height: 85px;
  height: 100%;
}

.comments-box-flex {
  display: flex;
}

.comments-img-main {
  max-width: 50px;
  width: 100%;
  max-height: 50px;
  height: 100%;
}

.comments-img-main .comment-img {
  width: 100%;
  height: 100%;
}

.comments-content-main {
  margin-left: 10px;
}

.comments-content-main .header-content-flex {
  display: flex;
  justify-content: space-between;
  min-width: 500px;
  width: 100%;
}

.comments-content-main .header-content-flex .content-title-main .page-title {
  font-size: 14px;
  font-family: "inter-bold", sans-serif;
  color: #2d8ca3;
}

.comments-content-main .header-content-flex .content-title-main .comment-time {
  font-size: 10px;
  font-family: "inter-bold", sans-serif;
  color: #535353;
}

.comments-content-text-main {
  min-width: 510px;
  width: 100%;
}

.comments-content-text-main .content-title-main .page-title {
  font-size: 12px;
  font-family: "inter-regular", sans-serif;
  color: #535353;
  margin-top: 5px;
}

.comments-description-box-main {
  margin-top: 15px;
}

.bunker-order-chart-box {
  max-width: 25% !important;
  width: 25% !important;
  flex: 0 0 33.33% !important;
  padding: 0 8px !important;
  margin-bottom: 15px !important;
}

.bunker-order-chart-graph-devider {
  padding: 10px !important;
  border: 1px solid #d6e5ff !important;
  border-radius: 10px !important;
  box-shadow: 0px 3px 6px #5777a91c !important;
  background: #f0f6ff !important;
  min-height: 160px !important;
  max-height: 160px !important;
}

.bunker-order-dashboard {
  width: 100%;
}

.filter-date-overflow .MuiStack-root {
  padding-top: 0px;
}

.flex-filter-content {
  display: flex;
  align-items: center;
  margin-top: 24px;
  gap: 16px;
}

.bunker-mt-grid {
  margin-top: 5px !important;
}

.tanker-title-document {
  margin-top: 25px;
  padding: 1px !important;
  padding-right: 0px !important;
}

.driver-loading-box {
  display: flex;
  justify-content: center;
}

/* Multiple documents Upload list */
.select-view-document-name {
  /* background-color:rgb(248, 244, 244); */
  width: 100%;
  /* height: 40px; */
  /* border: 1px solid rgb(189, 187, 187); */
  /* padding-left: 10px; */
  /* margin-top: 15px; */
  /* margin-top: 23px; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.multiple-doc {
  margin-bottom: 30px;
}

.document-name {
  display: flex;
  align-items: center;
  margin: 0 15px 5px 0;
  background: #f0f6ff;
  padding: 2px 7px;
  border-radius: 5px;
}

.document-name p {
  font-family: "inter-regular", sans-serif;
  font-size: 12px;
  line-height: 20px;
}

.document-name button {
  padding: 0;
}

.document-name button svg {
  font-size: 17px;
  padding-left: 6px;
}

.muiico-cancel {
  height: 0.8em !important;
  text-align: center;
  color: black !important;
  font-weight: bold !important;
}

.tanker-table-body .tanker-row-color td {
  background-color: #c9101052 !important;
}

.sales-copy-link-btn th {
  color: blue;
}

.sales-copy-btn-main tr td:nth-child(6) {
  color: blue;
}

.trader-copy-link-main tr td:nth-child(5) {
  color: blue;
}

.common-button .shift-tanker-submit-btn {
  margin-top: 44px;
}

/*Craft Maintenance */
.incre-btn-maintenance-main {
  margin-bottom: 10px;
}

.incre-btn-maintenance-main .inc-dic-btn,
.incre-btn-maintenance-main .inc-dic-btn:hover {
  margin-top: 0;
}

.cus-bunker-order-chart {
  overflow: auto;
  height: calc(100vh - 500px);
}

.img-view-list {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.loading-table tr td:nth-child(1) {
  width: 2px !important;
}

.loading-table tr th:first-child {
  border-radius: 10px 0px 0px 10px;
}

.loading-table tr th:last-child {
  border-radius: 0px 10px 10px 0 !important;
}

/* .shift-tanker-btn button {
  margin-bottom: 6px;
} */

.maintenance-view-grid-row {
  margin-bottom: 10px;
}

.challan-table-header tr th:first-child {
  border-radius: 10px 0px 0px 10px;
}

.challan-table-header tr th:last-child {
  border-radius: 0px 10px 10px 0 !important;
}

.edit-req-table tr th:first-child {
  border-radius: 10px 0px 0px 10px;
}

.edit-req-table tr th:last-child {
  border-radius: 0px 10px 10px 0 !important;
}

.maintenance-view-grid-row {
  margin-bottom: 10px;
}

.multiselect-input-placeholder .MuiInputBase-input {
  color: #c5c4c4;
  font-family: "inter-regular", sans-serif;
  font-size: 14px;
  font-weight: 0;
}

.order-activity-log th {
  background-color: #ddecff;
}

.order-activity-log tr th:first-child {
  border-radius: 10px 0px 0px 10px;
}

.order-activity-log tr th:last-child {
  border-radius: 0px 10px 10px 0 !important;
}

.google-map-main {
  width: 100%;
  /* height: 100%; */
}

.google-map-main .geo-location-map {
  width: 100%;
  height: 100vh;
}

.active-user-list-header {
  justify-content: flex-end;
}

.location-popup-main {}

.location-popup-main .location-popup-name {
  font-size: 15px;
  line-height: 25px;
  font-family: "inter-regular", sans-serif;
  margin: 0px;
}

.location-popup-main .location-popup-time {
  font-size: 15px;
  line-height: 25px;
  font-family: "inter-regular", sans-serif;
  margin: 0px;
}

/* tranker table start  */

.tanker-child-vessel,
.tanker-child-hours {
  font-size: 10px;
  color: #003d90;
  font-family: "inter-bold";
  line-height: 15px;
}

.tanker-dash-table table tbody tr td {
  vertical-align: top;
  padding-bottom: 10px !important;
}

.chart-header .tanker-dashboard-title {
  font-size: 15px !important;
}

/* .shit-tanker-modal .sift-tanker-modal-dialogBox{
  width:3000px !important;
  height: 250px ;
} */

/* tranker table end */
.tanker-dashboard-div {
  position: relative;
  z-index: 9;
}

/* Trip dashboard css */
.dashboard-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.dashboard-card {
  display: flex;
  background: #ffffff;
  border: 1px solid #dfe0e1;
  border-radius: 8px;
  margin-bottom: 10px;
}

.dashboard-left-main {
  max-width: 25%;
  flex: 0 0 25%;
  width: 100%;
  border-right: 1px solid #dfe0e1;
  padding: 12px 5px 5px;
}

.dashboard-image-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-trip-icon {
  width: 55px;
  height: 55px;
  object-fit: contain;
}

/* ORDERVIEW BASIS  START */
.order-view-ship-icon {
  width: 50% !important;
  height: 50px !important;
  object-fit: contain !important;
}

.progress-bar-main-order-view-ship {
  margin: 20px 0px 0px;
}

.tree-analysis-main-order-view {
  height: 201px;
  overflow: auto;
}

.order-view-dash {
  width: 80%;
  margin: 15px auto;
}

/* ORDERVIEW BASIS  END */

.dashboard-right-main {
  flex: 1;
  /* padding: 0px 15px; */
}

.ordersdashboard-right-main {
  flex: 1;
  padding: 7px 10px;
}

.dashboard-content-flex {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  gap: 3px;
}

.dashboard-content-flex .dashboard-content-label {
  font-size: 11px;
  font-family: "inter-regular";
  line-height: 15px;
  color: #8d98ab;
}

.dashboard-content-flex .dashboard-content-data {
  font-size: 11px;
  font-family: "inter-medium";
  line-height: 15px;
  color: #122b45;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-content-inner-flex {
  display: flex;
  align-items: center;
  gap: 3px;
}

.dashboard-card-order-view {
  width: 90%;
}

/* width */
.dashboard-card-scroll ::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.dashboard-card-scroll ::-webkit-scrollbar-track {
  border-radius: 2px;
  background: transparent;
}

/* Handle */
.dashboard-card-scroll ::-webkit-scrollbar-thumb {
  background: #ffffff80;
  border-radius: 2px;
}

/* Handle on hover */
.dashboard-card-scroll ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dashboard-card-scroll {
  overflow: auto;
  /* height: 600px; */
  height: 415px;
  padding-right: 5px;
}

.dashboard-colm .dashboard-trip-title {
  font-family: "poppins-bold", sans-serif;
  color: #003d90;
  line-height: 16px;
  font-size: 13px;
  margin-bottom: 10px;
}

.progress-bar-main {
  margin: 7px 0px 0px;
}

.dashboard-card .progress-bar-main .progress-bar {
  background-color: #dfe0e1;
}

.dashboard-card .trip-total-text {
  font-size: 8px;
  line-height: 10px;
  color: #122b45;
  font-family: "inter-semibold";
  text-align: center;
  margin-top: 4px;
}

.table-view-data .set-edit-timing-box:first-child {
  margin-top: 0 !important;
}

.record-notfound-main {
  background: #ffffff;
  border: 1px solid #dfe0e1;
  border-radius: 8px;
  padding: 15px;
}

.record-notfound-main .record-notfound-text {
  font-size: 12px;
  font-family: "inter-medium";
  line-height: 15px;
  color: #122b45;
  text-align: center;
}

.dashboard-content-inner-main {
  gap: 10px;
}

.upload-image-edit {
  display: flex;
  align-items: center;
  gap: 7px;
}

.view-upload-image {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: 10px;
}

.upload-image-flex {
  margin-top: 10px;
}

.width-pdf-design {
  width: 92%;
}

.document-box-display img {
  width: 100%;
  /* max-width: 70px; */
  height: 100%;
  object-fit: cover;
}

.tanker-chart-box .tanker-dash-table {
  min-height: 231px;
  max-height: 231px;
}

.tanker-dash-table .cate-table-container {
  height: 220px;
}

.invoice-popup-main .invoive-para {
  font-size: 16px;
  line-height: 22px;
  font-family: "inter-medium";
  color: #122b45;
  text-align: center;
}

.invoice-popup-main {
  margin: 20px 0px;
}

svg.info-icon {
  width: 17px;
  height: 17px;
}

.free-tanker-flex-box {
  display: flex;
}

.free-tanker-flex-box:last-child {
  margin-right: 5px;
}

.free-tanker-flex-box .free-tanker-icon-btn {
  padding: 5px;
}

.free-tanker-flex-box .free-tanker-icon-btn .free-tanker-view-icon,
.free-tanker-flex-box .free-tanker-icon-btn .free-tanker-free-icon {
  width: 20px;
  height: 20px;
}

.green-status {
  background: #09930954;
  color: green;
}

.red-status {
  background: #ff000047;
  color: red;
}

.yellow-status {
  background: #ebeb3e52;
  color: #91910b;
}

.orange-status {
  background-color: #ffa50063;
  color: orange;
}

.purple-status {
  background-color: #80008059;
  color: purple;
}

.blue-status {
  background: #0089ff6e;
  color: #0089ff;
}

.common-status-details {
  font-weight: 500 !important;
  padding: 4px 6px !important;
  font-size: 13px !important;
  line-height: 17px !important;
  text-align: center;
  font-family: "inter-regular";
  border-radius: 5px;
}

/* trader input basis in add inquiry side 28-02-2024 */

.trader-selection {
  background-color: #fff;
  font-size: 14px;
  font-family: "inter-regular";
}

/* preventive table view basis  */
.table-preventive-header {
  border: green !important;
}

.preventive-table tr,
.preventive-table th {
  border: solid black;
  border-right: 0;
  /* border-top: 0; */
}

.preventive-table th {
  font-weight: 900;
  min-width: 145px;
  max-width: 145px;
}

.preventive-table tr {
  font-weight: 300;
}

.preventive-table td {
  border-left: solid black;
  border-bottom: solid black;
  /* border-right: solid black; */
}

.preventive-table-mar {
  margin-bottom: 20px;
}

/* Document Center */

.document-center-title {
  width: 50%;
  display: flex;
  align-items: center;
}

.document-center-filter-option {
  flex-wrap: wrap;
}

.document-center-search,
.document-center-date-filter {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.document-center-date-filter {
  margin-top: 15px;
}

.table-li {
  list-style-type: none;
}

.preventive-no-data {
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
}

.error-message {
  color: #d32f2f;
  /* color: rgba(0, 0, 0, 0.6); */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  /* margin-left: 14px; */
}

tbody .tanker-grey-color td {
  background-color: #33353852 !important;
}

/* Dashboard side all the Boxes realted  */
.main-dashboard {
  width: 100%;
}

/* Dashboard side show active tabs basis  */
.active-dashboard-tab {
  color: #1976d2 !important;
  border-bottom: 1px solid !important;
}

/* Bunker order side Wharfage and parfoma pdf listing view */
.order-pdf-list-view {
  display: flex;
  align-items: center;
}

/* Tanker Map side Terminal Option */
.tanker-map-terminal {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.tanker-map-terminal-dropdown {
  margin-right: 15px;
  min-width: 200px;
}

.tanker-map-terminal-dropdown .terminal-dropdown {
  height: 40px;
}

/* set the dashboard side box basis */
.dashboard-boxes {
  width: 100%;
}

/* .breakdown-maintanance-margin {
  margin-left: 24px;
} */

.red-crew-row {
  background-color: #e15b5b;
}

.crew-table-body .red-crew-row td {
  background-color: #e15b5b;
  color: white;
}

.invoice-detail-item .download-pdf-pd {
  color: #1976d2;
  text-transform: uppercase;
  cursor: pointer;
}

@media print {
  @page {
    margin: 50px;
  }
}

.cus-scroll-table table,
.craft-preventive-table table {
  border-collapse: separate;
  border-spacing: 0;
}

.cus-scroll-table .preventive-table thead tr th:nth-child(1),
.cus-scroll-table .preventive-table tbody tr td:nth-child(1) {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
  border-left: solid black;
}

.cus-scroll-table .preventive-table thead tr th:nth-child(2),
.cus-scroll-table .preventive-table tbody tr td:nth-child(2),
.cus-scroll-table .preventive-table tbody tr td.item-col {
  position: sticky;
  left: 145px;
  background: white;
  z-index: 2;
  border-left: solid black;
}

.cus-scroll-table .preventive-table thead tr th:nth-child(3),
.cus-scroll-table .preventive-table tbody tr td:nth-child(3),
.cus-scroll-table .preventive-table tbody tr td.job-name-col {
  position: sticky;
  left: 290px;
  background: white;
  z-index: 3;
  border-left: solid black;
  border-right: solid black;
}

.cus-scroll-table .preventive-table thead tr th:nth-child(4),
.cus-scroll-table .preventive-table tbody tr td:nth-child(4) {
  border-left: none;
}

.cus-scroll-table.cus-scroll-four .preventive-table thead tr th:nth-child(4),
.cus-scroll-table.cus-scroll-four .preventive-table tbody tr td:nth-child(4),
.cus-scroll-table.cus-scroll-four .preventive-table tbody tr td.job-name-col {
  position: sticky;
  left: 435px;
  background: white;
  z-index: 4;
  border-right: solid black;
}

.cus-scroll-table.cus-scroll-four .preventive-table thead tr th:nth-child(5),
.cus-scroll-table.cus-scroll-four .preventive-table tbody tr td:nth-child(5) {
  border-left: none;
  border-right: none;
}

.cus-scroll-table .preventive-table thead tr:nth-child(1) th,
.craft-preventive-table .preventive-table thead tr:nth-child(1) th {
  border-bottom: 0;
  position: sticky;
  left: 0;
}

.table-preventive-header .table-title {
  position: sticky;
  left: 0;
  top: 0;
}

.preventive-table th:last-child,
.preventive-table td:last-child {
  border-right: solid black;
}

.doc-center-right {
  justify-content: flex-end;
  align-items: end;
  gap: 10px;
}

.doc-center-header .title-main .page-title {
  width: max-content;
}

.doc-center-right .search {
  margin-right: 0 !important;
}

.title-header-flex .search,
.title-header-flex .search:hover {
  margin: 0 !important;
  background: white;
}

.title-header-flex .common-button {
  margin-left: 0;
  display: flex;
  gap: 10px;
}

.title-header-flex .res-set-search {
  gap: 10px;
}

.justify-end {
  justify-content: end !important;
}

/* .craft-preventive-table .preventive-table thead tr th.craft-th,
.craft-preventive-table .preventive-table tbody tr td.craft-name {
  position: sticky;
  left: 0;
  background: white;
  z-index: 99;
  border-left: solid black;
}

.craft-preventive-table .preventive-table thead tr th.responsibility-th,
.craft-preventive-table .preventive-table tbody tr td.responsibility-col {
  position: sticky;
  left: 135px;
  background: white;
  z-index: 99;
  border-left: solid black;
}

.craft-preventive-table .preventive-table thead tr th.item-th,
.craft-preventive-table .preventive-table tbody tr td.item-col {
  position: sticky;
  left: 279.86px;
  background: white;
  z-index: 99;
  border-left: solid black;
}

.craft-preventive-table .preventive-table thead tr th.craft-job-th,
.craft-preventive-table .preventive-table tbody tr td.craft-job-name {
  position: sticky;
  left: 414.86px;
  background: white;
  z-index: 99;
  border-left: solid black;
  border-right: solid black;
} */

.sign-off-td .sign-off-btn,
.sign-off-td .sign-off-btn:hover {
  background: #eaac35;
  color: #ffffff;
  margin-left: 10px;
  border-radius: 4px;
  border: 0px;
  padding: 7px 8px;
}

/* .craft-preventive-table .preventive-table tbody tr td.table-jan {
  border-left: none;
} */
.dashboard-tab button {
  padding: 10px 12px;
  min-height: 40px;
  /* height: 40px; */
  font-size: 12px;
  /* opacity: 1; */
}

.dashboard-tab,
.dashboard-tab .MuiTabs-scroller {
  min-height: 40px !important;
}

.dashboard-tab .active-dashboard-tab,
.dashboard-tab .Mui-selected {
  background: #1976d2;
  color: white !important;
  border-radius: 10px 10px 0 0;
  border-bottom: 0 !important;
  opacity: 1;
  min-width: max-content;
}

.order-detail-tab .MuiTabs-fixed button {
  padding: 10px 5px;
  text-transform: capitalize;
}

.title-main .submit-btn-sec {
  display: flex;
  align-items: start;
  /* flex-direction: column; */
}

.submit-btn-sec .save-btn button {
  height: 35px;
}

.submit-btn-sec .flex-col {
  /* flex-direction: column; */
  gap: 10px;
  margin-bottom: 2px;
}

.grid-flex-start .Mui-error {
  margin-top: 0 !important;
}

.title-header .banker-order-header {
  align-items: start;
}

.banker-order-header form {
  width: 100%;
}

.trader-selection .MuiOutlinedInput-notchedOutline {
  border-color: #d6e5ff;
}

.order-pdf-list-view {
  /* max-width: 160px; */
  white-space: pre-line;
}

.tug-comp-order tr th:nth-child(1),
.tug-comp-order tr td:nth-child(1) {
  width: 1%;
}

/* .barge-common-box.tab-content-box{
  border-radius: 10px;
}*/
.admin-panel-content .tab-button-box {
  border-color: rgb(25 118 210 / 25%);
}

.invoice-activity-log .MuiTableCell-body,
.admin-panel-content .no-data-in-list {
  border-bottom-color: #d6e5ff;
}

.invoice-activity-log .MuiTableCell-head {
  border-bottom: none;
}

/* .document-wrapper .upload-wrapper{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px dashed #003d90;
} */
.upload-wrapper li {
  font-size: 12px;
}

.upload-wrapper {
  margin-top: 10px;
}

.bunker-detail-table table.invoice-activity-log tr td p {
  padding-top: 8px;
}

.add-remark-btn {
  display: flex;
  justify-content: end;
  gap: 10px;
}

.jus-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.jus-between h6 {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.expense-model-main .input-design-div .MuiInputBase-fullWidth {
  line-height: 16px;
}

/* .admin-sidebar-link-box .submenu-main{
margin-top: 15px;
} */
.submenu-main li:first-child {
  margin-top: 8px;
}

.use-img-td .user-no-img {
  display: flex;
}

.use-img-td .challan-img-model {
  width: 45px !important;
  object-fit: contain;
}

.use-img-td {
  width: max-content;
}

.use-img-td button {
  padding: 0;
}

.uploaded-user-img .user-img,
.user-img-main.uploaded-user-img {
  max-width: 50px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
  border-radius: 0px !important;
  -webkit-text-fill-color: #000;
}

.input-design-div .user-password-field {
  background: #fff;
}

.overflow-visible .MuiDialog-paper {
  overflow: visible;
}

.gap-10 {
  gap: 10px;
}

.supply-table.trader-copy-link-main tr th:nth-child(5),
.supply-table.trader-copy-link-main tr td:nth-child(5),
.supply-table.trader-copy-link-main tr th:nth-child(6),
.supply-table.trader-copy-link-main tr td:nth-child(6) {
  width: 3%;
}

.mt-0 {
  margin-top: 0 !important;
}

.supply-table.sales-copy-btn-main tr th:nth-child(7),
.supply-table.sales-copy-btn-main tr td:nth-child(7) {
  width: 3%;
}

.w-100 {
  width: 100%;
}

.challan-table-main .btn-approve,
.challan-table-main .btn-approve:hover {
  background: #04aa6d;
}

.challan-table-main .btn-reject,
.challan-table-main .btn-reject:hover {
  background: #dc3545;
}

.challan-table-main tr th:nth-child(1),
.challan-table-main tr td:nth-child(1) {
  width: 20%;
}

.challan-table-main tr th:nth-child(2),
.challan-table-main tr td:nth-child(2) {
  width: 20%;
}

.challan-table-main tr th:nth-child(3),
.challan-table-main tr td:nth-child(3) {
  width: 20%;
}

.challan-table-main tr th:nth-child(4),
.challan-table-main tr td:nth-child(4) {
  width: 20%;
}

.challan-table-main tr th:nth-child(5),
.challan-table-main tr td:nth-child(5) {
  width: 20%;
}

.check-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.br-none {
  border: none;
}

.supply-table.tug-curr-table tr th:nth-child(7),
.supply-table.tug-curr-table tr td:nth-child(7) {
  width: 2%;
}

.supply-table.other-exp-table tr th:nth-child(5),
.supply-table.other-exp-table tr td:nth-child(5),
.supply-table.other-exp-table tr th:nth-child(6),
.supply-table.other-exp-table tr td:nth-child(6) {
  width: 2%;
}

.expenses-table tr th:last-child,
.expenses-table tr td:last-child {
  width: 3%;
}

.supply-table tr td button svg {
  font-size: 20px;
}

.card-right-content .card-total.min-26 {
  min-height: 26px;
  display: flex;
  align-items: center;
}

.card-total .net-per {
  color: #1976d2;
  font-weight: 500;
  font-size: 12px;
  font-family: "poppins-regular", sans-serif;
}

.custom-vessel-search {
  position: relative;
}

.cus-search-opt-box {
  background: #fff;
  padding: 10px;
  /* box-shadow: 10px 5px 5px #a7a3a3; */
  border-radius: 3px solid black;
  border: 1px solid #fff;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: absolute;
  z-index: 99;
  top: 35px;
  left: 0;
  right: 0;
  max-height: 190px;
  overflow: auto;
}

.cus-search-opt-box .cust-search-item {
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}

.cus-search-opt-box .cust-search-item:hover {
  background-color: #a6ccff;
}

.cus-search-circular {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cus-search-mar {
  margin-bottom: 0px !important;
}

/* datepicker related */

.datepicker-redate .MuiPickersCalendarHeader-root {
  margin-top: 5px;
}

.datepicker-redate .MuiPickersCalendarHeader-label {
  font-size: 13px;
}

.datepicker-redate .MuiPickersDay-root,
.datepicker-redate .MuiButtonBase-root {
  padding: 5px;
  font-size: 11px;
  /* width: 30px;
  height: 30px; */
}

.datepicker-redate .MuiButtonBase-root svg {
  font-size: 19px;
}

.datepicker-redate .MuiTypography-caption {
  height: 25px;
  width: 30px;
}

.datepicker-redate .MuiDayCalendar-weekContainer {
  margin: 0;
}

.datepicker-redate .MuiMultiSectionDigitalClockSection-item {
  font-size: 12px;
  margin: 0;
}

.datepicker-redate .MuiMultiSectionDigitalClockSection-root,
.datepicker-redate .MuiMultiSectionDigitalClock-root {
  max-height: 256px !important;
}

.datepicker-redate .MuiMultiSectionDigitalClockSection-root:after {
  display: none;
}

.datepicker-redate .MuiDateCalendar-root {
  max-height: 256px !important;
}

.datepicker-redate .MuiDialogActions-root .MuiButton-textPrimary {
  padding: 0;
  font-size: 15px;
  font-family: Poppins;
  color: #551067;
  min-width: 50px;
}

.update_priofile_btn {
  margin-top: 12px;
}

.propose-drop .MuiSelect-select {
  display: flex;
  min-height: 29px !important;
  align-items: center;
}

.loaded-comp-qty-box {
  border: 0px;
  padding: 7px 8px;
  background: #1976d2;
  color: white !important;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 13px;
}

.loading-box {
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 10px;
}

.loading-box .download-all-tt-btn {
  margin-right: 10px !important;
}

.table-max-width {
  max-height: 400px;
}

.barge-common-box-set.min-h-auto {
  min-height: auto;
}

.purchase-option-search {
  position: absolute;
}

.searching-suggestion-box {
  width: 280px;
  background-color: #ffffff;
  height: 180px;
  overflow-y: auto;
  position: relative;
  z-index: 100;
  top: 4px;
  left: -40px;
  box-shadow: 0px 3px 4px #ccc4b3;
  border-radius: 4px;
  font-family: "poppins-regular", sans-serif;
}

.searching-suggestion-box li {
  list-style-type: none;
  padding: 6px 10px;
  font-size: 14px;
  font-family: "poppins-regular", sans-serif;
}

.searching-suggestion-box li:hover {
  background-color: #a6ccff;
}

.finance-box {
  display: flex;
  margin: 5px;
}

.flex-bank-details {
  margin-top: 10px;
  justify-content: space-between;
}

.bank-detail-input {
  width: 200px;
}

.highligtMenu {
  background: transparent linear-gradient(270deg, #ffffff1a 0%, #065db3 100%) 0% 0%;
  border-radius: 5px;
}

.job-type-tab {
  background-color: #fff;
  color: black;
  border: 1px solid #d6e5ff;
  border-right: unset;
}

.job-type-tab .craft-main-btn {
  background-color: #f0f6ff;
  color: black;
  width: calc(100% / 5);
  /* border: 1px solid #1976d2; */
  border-right: 1px solid #d6e5ff;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}

.job-type-tab .craft-main-btn:hover {
  background-color: #1976d2;
  color: #ffffff;
}

.job-type-tab .selected-job-type {
  background-color: #1976d2;
  color: #ffffff;
}

.box-margin {
  margin-bottom: 12px;
}

.bank-dash-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bank-box .bank-name {
  color: #003d90;
  font-size: 16px;
  line-height: 20px;
  font-family: "inter-bold", sans-serif;
}

.no-data-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.padding-zero {
  padding: 0 !important;
}

.invoice-detail-item-shift-tanker {
  display: flex;
}

.invoice-detail-shift-tanker-label {
  margin-right: 5px;
}

.nm-tag {
  display: flex;
  justify-content: flex-end;
}

.nm-tag .nm-tag-span {
  border: 2px solid red;
  font-weight: bold;
  padding: 1px 5px;
  font-size: 10px;
}

.seperate-edit-icon {
  min-width: auto !important;
  /* padding: 0px !important; */
  margin-bottom: 0px !important;
  padding: 3px;
}

.seperate-edit-icon svg {
  width: 20px !important;
  height: 13px !important;
}

.single-edit-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-table-body .hide-user-row-color td {
  background-color: #c9101052 !important;
}

.modal-update-btn {
  margin-top: 50px;
}

.table-container .preventive-table .table-preventive-header .table-main-header {
  border: none;
  background: #8080805e;
}

.heading-line-wrapper {
  position: relative;
  text-align: center;
  padding: 40px 0;
}

.heading-line-wrapper .heading-line {
  display: flex;
  justify-content: center;
}

.heading-line-wrapper .heading-line::before {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  height: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: #ddecff;
  z-index: 0;
}

.heading-line-wrapper .heading-line .head-title {
  background: #ffffff;
  margin-bottom: 0 !important;
  z-index: 999;
  margin-right: 0 !important;
  padding: 0 10px;
  font-size: 20px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.tree-analysis-main .tree-box-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 231px;
}

.modal-invoice-main .modal-invoice-detail {
  min-height: 280px;
  max-height: 400px;
  max-width: 800px;
}

.page-table-main .table-container {
  background-color: transparent;
  box-shadow: none;
  overflow: auto;
  max-width: calc(100vw - 0px);
}

.page-table-main .table {
  min-width: 1000px;
}

.admin-modal .admin-modal-inner {
  width: calc(100% - 30px);
  max-height: calc(100vh - 40px);
  border: 1px solid lightgray;
  border-radius: 10px;
  outline: none;
  background-color: #fff;
}

.admin-modal-body {
  padding: 20px;
}

.admin-modal-hgt-scroll {
  max-height: calc(100vh - 194px);
  overflow: auto;
  padding: 0 5px 0px 0px;
  margin-right: -5px;
}

.admin-modal-header .admin-modal-title {
  color: #003d90;
  font-size: 16px;
  line-height: 20px;
  font-family: "inter-bold", sans-serif;
}

.admin-modal-header {
  padding: 15px 20px 0px;
}

.admin-modal-header .modal-close-btn {
  padding: 0px;
  min-width: 30px;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  top: 10px;
}

.invoice-table-body .invoice-row-color td {
  background-color: #c9101052 !important;
}

.bank-flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-content-width {
  width: 100%;
}

.card-price-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-price-box .card-price.previous-card-price {
  color: #585d65;
}

.card-price .financial-year-label {
  color: #585d65;
  font-size: 10px;
  font-weight: 10;
  font-family: "poppins-regular", "sans-serif";
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}